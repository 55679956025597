import {MaterialSymbolsArrowDownwardRounded} from '@/assets/icons/comm/MaterialSymbolsArrowDownwardRounded';
import {MaterialSymbolsArrowUpwardRounded} from '@/assets/icons/comm/MaterialSymbolsArrowUpwardRounded';
import BaseTopNav from '@/components/base/baseTopNav';
import FeaturesSettingTopTitle from '@/components/features/featuresSettingTopTitle';
import {useModel} from '@umijs/max';
import {useTranslation} from 'react-i18next';

/**
 * 涨跌色设置
 * **/
export default () => {
  const { t } = useTranslation();

  const { upDownColorConfig, setUpDownColorConfig } = useModel('system');

  return (
    <div id="Language">
      <BaseTopNav />

      {/*顶部标题*/}
      <FeaturesSettingTopTitle
        title={t('涨跌色设置')}
        description={t('根据你的喜好选择')}
      />
      <div
        className={`flex py-2 items-center px-4 border-1 mx-4 rounded-md mt-6  cursor-pointer ${
          upDownColorConfig === 'upGreen' ? '' : 'border-borderColor'
        }`}
        onClick={() => {
          setUpDownColorConfig('upGreen');
        }}
      >
        {/* 第一列 */}
        <div className="flex-2 font-bold text-sm items-center overflow-hidden">
          <div className="truncate flex items-center">
            <MaterialSymbolsArrowUpwardRounded className="text-xl mt-[-8px] text-successColor font-bold" />
            <MaterialSymbolsArrowDownwardRounded className="text-xl mt-[8px] ml-[-10px] text-errorColor font-bold" />
            <span className="ml-2">{t('绿涨红跌')}</span>
          </div>
          <div className="text-iconFontColor text-xs truncate mt-3">
            BTC-USDT
          </div>
        </div>

        {/* 第二列 */}
        <div className="flex-1 pr-4 ">
          <div className=" h-[30px] rounded-md bg-backgroundAuxiliaryColor "></div>
          <div className=" h-[30px] rounded-md bg-backgroundAuxiliaryColor mt-2"></div>
        </div>
        {/* 第三列 */}
        <div className="flex-1 text-right">
          <div className=" w-[75px] text-center rounded-md text-md bg-successColor text-white h-[30px] leading-[30px]">
            + 4.56 %
          </div>
          <div className="mt-2 w-[75px] text-center rounded-md text-md bg-errorColor text-white h-[30px] leading-[30px]">
            - 3.12 %
          </div>
        </div>
      </div>

      <div
        className={`flex py-2 items-center px-4 border-1 mx-4 rounded-md mt-6 cursor-pointer ${
          upDownColorConfig === 'upRed' ? '' : 'border-borderColor'
        }`}
        onClick={() => {
          setUpDownColorConfig('upRed');
        }}
      >
        {/* 第一列 */}
        <div className="flex-2 font-bold text-sm items-center overflow-hidden">
          <div className="truncate flex items-center">
            <MaterialSymbolsArrowUpwardRounded className="text-xl mt-[-8px]  text-errorColor font-bold" />
            <MaterialSymbolsArrowDownwardRounded className="text-xl mt-[8px] ml-[-10px] text-successColor font-bold" />
            <span className="ml-2">{t('红涨绿跌')}</span>
          </div>

          <div className="text-iconFontColor text-xs truncate mt-3">
            ETH-USDT
          </div>
        </div>

        {/* 第二列 */}
        <div className="flex-1 pr-4 ">
          <div className=" h-[30px] rounded-md bg-backgroundAuxiliaryColor "></div>
          <div className=" h-[30px] rounded-md bg-backgroundAuxiliaryColor mt-2"></div>
        </div>
        {/* 第三列 */}
        <div className="flex-1 text-right">
          <div className=" w-[75px] text-center rounded-md text-md  bg-errorColor text-white h-[30px] leading-[30px]">
            + 4.56 %
          </div>
          <div className="mt-2 w-[75px] text-center rounded-md text-md bg-successColor text-white h-[30px] leading-[30px]">
            - 3.12 %
          </div>
        </div>
      </div>
    </div>
  );
};
